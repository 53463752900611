var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","title":"Re-Process Email Batch"},on:{"hidden":function($event){return _vm.$emit('modal-closed')},"ok":_vm.confirmHandler},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isProcessing},on:{"click":function($event){return ok()}}},[_vm._v(" Re-Process "),(_vm.isProcessing)?_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}):_vm._e()],1)]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-card-text',[(_vm.ids.length === 1)?_c('div',[_vm._v(" Are you sure you want to re-process the email batch "),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.ids[0]))]),_vm._v("? "),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-group',{staticClass:"pt-1"},[_c('b-form-radio-group',{attrs:{"size":"small","button-variant":"outline-primary","options":[
              { text: 'All', value: 'all' },
              { text: 'Extraction', value: 'extraction' },
              { text: 'API', value: 'api' },
              { text: 'Upload Doc', value: 'uploadDoc' } ],"buttons":""},model:{value:(_vm.reprocessType),callback:function ($$v) {_vm.reprocessType=$$v},expression:"reprocessType"}})],1)],1)]):_c('div',[_vm._v(" Are you sure you want to re-process the following email batches? "),_vm._l((_vm.ids),function(id,index){return _c('div',{key:index,staticClass:"text-primary"},[_vm._v(" "+_vm._s(id)+" ")])})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }