<template>
  <chip
    v-if="status"
    :variant="chipVariant"
  >
    {{ status }}
  </chip>
</template>

<script>
import Chip from '@/components/UI/Chip.vue'

export default {
  components: {
    Chip,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    chipVariant() {
      if (this.status === 'completed') {
        return 'success'
      }

      if (this.status === 'waiting') {
        return 'warning'
      }

      if (this.status === 'failed') {
        return 'danger'
      }

      return 'primary'
    },
  },
}
</script>
