<template>
  <div>
    <h2>Transactions</h2>
    <EmailBatches />
  </div>
</template>

<script>
import EmailBatches from '@/components/EmailBatches/EmailBatches.vue'

export default {
  components: {
    EmailBatches,
  },
}
</script>
