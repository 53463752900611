<template>
  <div>
    <b-alert
      variant="danger"
      :show="!loading && error ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ error }}
        </p>
      </div>
    </b-alert>

    <b-card
      v-if="!error"
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-dropdown
              text="Actions"
              variant="primary"
            >
              <b-dropdown-item
                :disabled="selectedRecords.length === 0"
                @click="deleteMultipleHandler"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="selectedRecords.length === 0"
                @click="reProcessMultipleHandler"
              >
                <feather-icon icon="RefreshCwIcon" />
                <span class="align-middle ml-50">Re-Process</span>
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              variant="outline-primary"
              class="ml-1"
              @click="uploadEmailBatch = true"
            >
              Upload Email Batch
            </b-button>

            <b-button
              variant="outline-primary"
              class="ml-1"
              @click="uploadTransaction = true"
            >
              Upload Transaction
            </b-button>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <div class="mr-1">
              <feather-icon
                v-if="isAdmin"
                v-b-tooltip.hover
                icon="FilterIcon"
                class="cursor-pointer mr-1"
                size="20"
                title="Filter Email Batches"
                @click.stop="filterEmailBatches = true"
              />

              <label>Refresh Rate</label>
              <v-select
                v-model="refreshInterval"
                :options="refreshIntervalOptions"
                :reduce="option => option.value"
                :clearable="false"
                class="refresh-rate-selector d-inline-block mx-50"
              />
            </div>
            <div>
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table-simple
        :class="{
          'table-busy': loading
        }"
        class="batches-table"
      >
        <colgroup>
          <col
            v-for="(tableColumn) of tableColumns"
            :key="tableColumn.key"
            :style="{ width: tableColumn.width + '%' }"
          >
        </colgroup>
        <b-thead>
          <b-tr>
            <template
              v-for="tableColumn of tableColumns"
            >
              <b-th
                v-if="tableColumn.key === 'select'"
                :key="tableColumn.key"
              >
                <b-form-checkbox
                  v-model="allRecordsSeleted"
                  :disabled="batches.length === 0"
                  @change="toggleRecordsSelection"
                />
              </b-th>

              <b-th
                v-if="tableColumn.key !== 'select' && tableColumn.sortable"
                :key="tableColumn.key"
                :aria-sort="sortBy === tableColumn.key ? sortDesc ? 'descending' : 'ascending' : 'none'"
                @click="customSort(tableColumn.key)"
              >
                {{ tableColumn.label }}
              </b-th>

              <b-th
                v-if="tableColumn.key !== 'select' && !tableColumn.sortable"
                :key="tableColumn.key"
              >
                {{ tableColumn.label }}
              </b-th>
            </template>
          </b-tr>
          <b-tr>
            <template
              v-for="tableColumn of tableColumns"
            >
              <b-th
                v-if="tableColumn.customSearch"
                :key="tableColumn.key"
              >
                <b-form
                  @submit.prevent="searchSubmitHandler"
                >
                  <b-form-input
                    v-model="searchBy[tableColumn.key]"
                    trim
                    :disabled="loading"
                    placeholder="Search"
                  />
                </b-form>
              </b-th>
              <b-th
                v-else
                :key="tableColumn.key"
              />
            </template>
          </b-tr>
        </b-thead>
        <b-tbody v-if="!loading">
          <template v-for="(batch) of batches">
            <b-tr
              :key="`main-row-${batch.id}`"
              :class="{
                'has-row-details': expandedRowIds.includes(batch.id)
              }"
            >
              <b-td>
                <b-form-checkbox
                  v-model="selectedRecords"
                  :value="batch.id"
                />
              </b-td>
              <b-td>
                <b-link
                  :to="{ name: 'verification', params: { id: batch.id } }"
                  class="font-weight-bold d-block text-nowrap batch-link"
                  :class="{ 'text-secondary': !isVerificationLinkEnabled(batch) }"
                  :disabled="!isVerificationLinkEnabled(batch)"
                >
                  {{ batch.id }}
                </b-link>
              </b-td>
              <b-td>
                {{ batch.email_from }}
              </b-td>
              <b-td>
                {{ batch.email_subject }}
              </b-td>
              <b-td>
                {{ batch.matched_profile_name }}
              </b-td>
              <b-td>
                {{ batch.confirmation_numbers ? batch.confirmation_numbers.join(', ') : '' }}
              </b-td>
              <b-td>
                <status-tag :status="batch.status" />
              </b-td>
              <b-td>
                {{ formatedDate(batch.created_at) }}
              </b-td>
              <b-td>
                <div class="text-nowrap">
                  <feather-icon
                    v-b-tooltip.hover
                    :icon="expandedRowIds.includes(batch.id) ? 'ChevronDownIcon' : 'ChevronUpIcon'"
                    class="cursor-pointer"
                    size="18"
                    title="View Email Batch Details"
                    @click="toggleRowDetails(batch.id)"
                  />
                  <div
                    class="d-inline"
                    @click="setTimelineId(batch.id)"
                  >
                    <timeline
                      :batch-id="`${batch.id}`"
                      :icon-size="'18'"
                      class="ml-1"
                      @show-details-change="handleShowDetailsChange"
                    />
                  </div>

                  <b-spinner
                    v-if="!downloadTransaction && downloadingZips.includes(batch.id)"
                    small
                    label="Small Spinner"
                    class="ml-1"
                  />
                  <!-- <feather-icon
                    v-else
                    v-b-tooltip.hover
                    title="Download Email Batch (Zip)"
                    icon="DownloadCloudIcon"
                    class="cursor-pointer ml-1"
                    size="18"
                    @click.stop="downloadZip(batch.id)"
                  /> -->

                  <b-spinner
                    v-if="downloadTransaction && downloadingZips.includes(batch.id)"
                    small
                    label="Small Spinner"
                    class="ml-1"
                  />
                  <feather-icon
                    v-else
                    v-b-tooltip.hover
                    title="Download Transaction (Zip)"
                    icon="DownloadIcon"
                    class="cursor-pointer ml-1"
                    size="18"
                    @click.stop="downloadZip(batch.id, 'Transaction')"
                  />

                  <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="ml-1 cursor-pointer"
                    size="18"
                    title="Delete Email Batch"
                    @click="deleteHandler(batch.id)"
                  />
                </div>
              </b-td>
            </b-tr>

            <b-tr
              v-if="expandedRowIds.includes(batch.id)"
              :key="`detail-row-${batch.id}`"
              class="p-0 m-0"
            >
              <b-td
                colspan="12"
                class="p-0 m-0"
              >
                <email-batch-details
                  :email-batch="batch"
                />
              </b-td>
            </b-tr>
          </template>

        </b-tbody>
      </b-table-simple>

      <div
        v-if="loading"
        class="text-center m-3 table-busy-spinner"
      >
        <b-spinner
          variant="primary"
        />
      </div>

      <div
        v-if="!loading && batches.length === 0"
        class="text-center m-3"
      >
        No records found!
      </div>

      <div
        v-if="!loading"
        class="mx-2 mt-1 mb-2"
      >
        <detailed-pagination
          :per-page="perPage"
          :current-page="currentPage"
          :total-records="totalRecords"
          :local-records="batches.length"
          @page-changed="pageChanged"
        />
      </div>
    </b-card>

    <delete-email-batch
      v-if="deleteBatches.length > 0"
      :ids="deleteBatches"
      @modal-closed="deleteBatches = []"
      @deleted="fetchBatches"
    />

    <re-process-email-batch
      v-if="reProcessBatches.length > 0"
      :ids="reProcessBatches"
      @modal-closed="reProcessBatches = []"
      @completed="fetchBatches"
    />

    <upload-email-batch
      v-if="uploadEmailBatch"
      @modal-closed="uploadEmailBatch = false"
      @uploaded="fetchBatches"
    />

    <upload-zip
      v-if="uploadTransaction === true"
      title="Upload Transaction"
      label="Zip File (.zip):"
      api-endpoint="/pipeline/upload_transaction/"
      replace
      @uploaded="fetchBatches"
      @modal-closed="uploadTransaction = false"
    />

    <filter-options
      v-if="filterEmailBatches"
      @modal-closed="filterEmailBatches = false"
    />
  </div>
</template>

<script>

import axios from 'axios'
import moment from 'moment-timezone'
import {
  BCard, BRow, BCol, BSpinner, BLink, BAlert, BFormCheckbox, BDropdown, BDropdownItem, BForm, BFormInput,
  BTableSimple, BThead, BTr, BTbody, BTh, BTd, VBTooltip, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import WS from '@/utils/ws'
import bus from '@/bus'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import UploadZip from '@/components/UI/UploadZip.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FilterOptions from '@/components/UI/FilterOptions.vue'
import DeleteEmailBatch from '@/components/EmailBatches/DeleteEmailBatch.vue'
import ReProcessEmailBatch from '@/components/EmailBatches/ReProcessEmailBatch.vue'
import Timeline from '@/components/UI/Timeline/Timeline.vue'
import EmailBatchDetails from '@/components/EmailBatches/EmailBatchDetails.vue'
import UploadEmailBatch from '@/components/EmailBatches/UploadEmailBatch.vue'
import StatusTag from '@/components/EmailBatches/StatusTag.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BSpinner,
    BLink,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BForm,
    BFormInput,
    vSelect,
    DeleteEmailBatch,
    ReProcessEmailBatch,
    Timeline,
    BTableSimple,
    BThead,
    BTr,
    BTbody,
    BTh,
    BTd,
    DetailedPagination,
    UploadZip,
    FilterOptions,
    EmailBatchDetails,
    BButton,
    UploadEmailBatch,
    StatusTag,
  },
  data() {
    return {
      loading: true,
      error: null,
      currentPage: 1,
      perPage: 10,
      totalRecords: 0,
      perPageOptions: [10, 25, 50, 100],
      batches: [],
      tableColumns: [
        { key: 'select', label: '', width: 1 },
        {
          key: 'id', label: 'ID', sortable: true, customSearch: true, width: 8,
        },
        {
          key: 'email_from', label: 'Email From', sortable: true, customSearch: true, width: 17,
        },
        {
          key: 'email_subject', label: 'Email Subject', sortable: true, customSearch: true, width: 20,
        },
        {
          key: 'matched_profile_name', label: 'Matched Profile', sortable: true, customSearch: true, width: 25,
        },
        {
          key: 'confirmation_number', label: 'Confirmation Numbers', customSearch: true, width: 15,
        },
        {
          key: 'status', label: 'Status', sortable: true, customSearch: true, width: 7,
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, width: 8,
        },
        { key: 'actions', label: 'Actions', width: 6 },
      ],
      deleteBatches: [],
      reProcessBatches: [],
      selectedRecords: [],
      allRecordsSeleted: false,
      sortBy: 'created_at',
      sortDesc: true,
      initialized: false,
      searchBy: {
        id: null,
        email_from: null,
        email_subject: null,
        matched_profile_name: null,
        confirmation_number: null,
        status: null,
      },
      downloadingZips: [],
      expandedRowIds: [],
      uploadEmailBatch: false,
      uploadTransaction: false,
      refreshIntervalOptions: [
        { label: '---', value: 0 },
        { label: '10 sec', value: 10 },
        { label: '20 sec', value: 20 },
        { label: '30 sec', value: 30 },
        { label: '1 min', value: 60 },
      ],
      refreshInterval: 0,
      clearInterval: null,
      filterEmailBatches: false,
      reFetchEmailBatches: false,
      downloadTransaction: false,
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    batchIds() {
      return this.batches.map(batch => batch.id)
    },
    stickyFilters() {
      return {
        searchBy: this.searchBy,
        perPage: this.perPage,
      }
    },
    selectedProjectCountries() {
      return this.$store.getters['auth/selectedProjectCountries']
    },
    filterBy() {
      return {
        project_countries: this.selectedProjectCountries.map(e => `${e.countryCode}_${e.project}`).join(','),
      }
    },
  },
  watch: {
    perPage() {
      if (this.initialized) {
        this.currentPage = 1
        this.fetchBatches()
      }
    },
    selectedRecords(newValue) {
      if (this.batches.length > 0 && newValue.length === this.batches.length) {
        this.allRecordsSeleted = true
      } else {
        this.allRecordsSeleted = false
      }
    },
    batches() {
      this.selectedRecords = this.selectedRecords.filter(id => {
        const index = this.batches.findIndex(batch => batch.id === id)
        return index !== -1
      })

      this.expandedRowIds = this.expandedRowIds.filter(id => {
        const index = this.batches.findIndex(batch => batch.id === id)
        return index !== -1
      })
    },
    stickyFilters: {
      handler() {
        localStorage.setItem('email-batches-filter', JSON.stringify(this.stickyFilters))
      },
      deep: true,
    },
    batchIds(newValue, oldValue) {
      const addedBatches = newValue.filter(item => !oldValue.includes(item))
      const removedBatches = oldValue.filter(item => !newValue.includes(item))
      addedBatches.forEach(batchId => {
        WS.joinRoom(`email_batch_status_tag_${batchId}`)
      })
      removedBatches.forEach(batchId => {
        WS.leaveRoom(`email_batch_status_tag_${batchId}`)
      })
    },
    refreshInterval(newVal, oldVal) {
      if (newVal === oldVal) {
        return
      }

      this.autoDataRefresh()
    },
    filterBy() {
      if (!this.loading) {
        this.fetchBatches()
      } else {
        this.reFetchEmailBatches = true
      }
    },
    loading(newVal) {
      if (!newVal && this.reFetchEmailBatches) {
        this.reFetchEmailBatches = false
        this.fetchBatches()
      }
    },
  },
  created() {
    const currentPage = localStorage.getItem('email-batches-last-active-page')

    if (currentPage) {
      this.currentPage = parseInt(currentPage, 10)
    }

    const batchesFilterData = localStorage.getItem('email-batches-filter')
    if (batchesFilterData) {
      const batchesFilter = JSON.parse(batchesFilterData)
      if (batchesFilter.searchBy) {
        this.searchBy = batchesFilter.searchBy
      }
      if (batchesFilter.perPage) {
        this.perPage = batchesFilter.perPage
      }
    }
    this.$nextTick(() => {
      this.initialized = true
    })

    this.fetchBatches()
    this.initialize()
    this.autoDataRefresh()
  },
  destroyed() {
    localStorage.setItem('email-batches-last-active-page', this.currentPage)
    this.cleanup()
  },
  methods: {
    initialize() {
      bus.$on('wsData/emailBatchStatusTag', this.onEmailBatchStatusTag)
    },
    cleanup() {
      this.batchIds.forEach(batchId => {
        WS.leaveRoom(`email_batch_status_tag_${batchId}`)
      })
      bus.$off('wsData/emailBatchStatusTag', this.onEmailBatchStatusTag)

      clearInterval(this.clearInterval)
    },
    pageChanged(page) {
      this.currentPage = page
      this.fetchBatches()
    },
    searchSubmitHandler() {
      this.currentPage = 1
      this.fetchBatches()
    },
    customSort(sortBy) {
      const sortDesc = sortBy === this.sortBy ? !this.sortDesc : false
      this.sortBy = sortBy
      this.sortDesc = sortDesc
      this.fetchBatches()
    },
    fetchBatches() {
      this.loading = true
      const data = {
        ...this.filterBy,
      }
      const params = {
        page_size: this.perPage,
        page: this.currentPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        ...this.searchBy,
      }
      axios.post('/email-batches/filter_list/', data, {
        params,
      })
        .then(res => {
          this.batches = res.data.results
          this.totalRecords = res.data.count
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          const errorResponse = error?.response
          if (errorResponse && errorResponse.status === 404 && this.currentPage > 1) {
            this.currentPage -= 1
            this.fetchBatches()
          } else {
            this.error = error?.response?.data?.detail || ' Error fetching email batches'
          }
        })
    },
    formatedDate(dateString) {
      return moment.utc(dateString).tz('America/New_York').format('DD/MM/YYYY HH:mm')
    },
    deleteHandler(id) {
      this.deleteBatches = [id]
    },
    deleteMultipleHandler() {
      this.deleteBatches = [...this.selectedRecords]
    },
    reProcessMultipleHandler() {
      this.reProcessBatches = [...this.selectedRecords]
    },
    toggleRecordsSelection(checked) {
      this.selectedRecords = checked ? this.batches.map(doc => doc.id) : []
    },
    downloadZip(batchId, type = 'emailBatch') {
      let apiEndpoint = '/pipeline/download_email_batch/'

      if (type === 'Transaction') {
        this.downloadTransaction = true

        apiEndpoint = '/pipeline/download_transaction/'
      }

      this.downloadingZips.push(batchId)

      axios.get(apiEndpoint, {
        params: {
          email_batch_id: batchId,
        },
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${batchId}.zip`)
        document.body.appendChild(link)
        link.click()

        this.downloadingZips = this.downloadingZips.filter(itemId => itemId !== batchId)

        if (type === 'Transaction') {
          this.downloadTransaction = false
        }
      }).catch(async error => {
        // convert blob response to json
        let responseDataJSON = null
        if (error?.response?.data) {
          const responseData = await error?.response?.data.text()
          responseDataJSON = JSON.parse(responseData)
        }

        const message = responseDataJSON?.detail || 'Error downlaoding batch'
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.downloadingZips = this.downloadingZips.filter(itemId => itemId !== batchId)

        if (type === 'Transaction') {
          this.downloadTransaction = false
        }
      })
    },
    toggleRowDetails(id) {
      if (this.expandedRowIds.includes(id)) {
        this.expandedRowIds = this.expandedRowIds.filter(itemId => itemId !== id)
      } else {
        this.expandedRowIds.push(id)
      }
    },
    onEmailBatchStatusTag(data) {
      this.batches.forEach((item, index) => {
        if (item.id === data.batch_id) {
          this.batches[index].status = data.status
        }
      })
    },
    isVerificationLinkEnabled(batch) {
      if (batch.status === 'waiting') {
        return true
      }

      if (['ready', 'submitted'].includes(batch.verification_status)) {
        return true
      }

      if (batch.verification_status === 'disabled') {
        return false
      }

      return batch.assembly_triggered
    },
    autoDataRefresh() {
      clearInterval(this.clearInterval)

      if (!this.refreshInterval) {
        return
      }

      this.clearInterval = setInterval(() => {
        this.fetchBatches()
      }, this.refreshInterval * 1000)
    },
    handleShowDetailsChange(showDetails) {
      if (showDetails) {
        clearInterval(this.clearInterval)

        return
      }

      this.autoDataRefresh()
    },
    setTimelineId(batchID) {
      bus.$emit('setTimelineID', batchID)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.refresh-rate-selector {
  width: 120px;
}
.batch-link.disabled {
  pointer-events: none;
}
.table-busy {
  opacity: 0.55;
  pointer-events: none;
}
.table-busy-spinner {
 opacity: 0.55;
}
.batches-table {
  td {
    padding: 0.4rem 0.5rem;
    vertical-align: baseline;
  }
  th {
    padding: 0.8rem 0.5rem;
  }
  tr.has-row-details {
    border-bottom: hidden;
  }
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
