<template>
  <b-modal
    v-model="showModal"
    centered
    title="Re-Process Email Batch"
    @hidden="$emit('modal-closed')"
    @ok="confirmHandler"
  >
    <b-card-text>
      <div v-if="ids.length === 1">
        Are you sure you want to re-process the email batch <span class="text-primary">{{ ids[0] }}</span>?
        <div class="d-flex justify-content-center">
          <b-form-group class="pt-1">
            <b-form-radio-group
              v-model="reprocessType"
              size="small"
              button-variant="outline-primary"
              :options="[
                { text: 'All', value: 'all' },
                { text: 'Extraction', value: 'extraction' },
                { text: 'API', value: 'api' },
                { text: 'Upload Doc', value: 'uploadDoc' },
              ]"
              buttons
            />
          </b-form-group>
        </div>
      </div>
      <div v-else>
        Are you sure you want to re-process the following email batches?
        <div
          v-for="(id, index) of ids"
          :key="index"
          class="text-primary"
        >
          {{ id }}
        </div>
      </div>

    </b-card-text>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="isProcessing"
        @click="ok()"
      >
        Re-Process
        <b-spinner
          v-if="isProcessing"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import {
  BModal, BCardText, BButton, BSpinner, BFormRadioGroup, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
    BSpinner,
    BFormRadioGroup,
    BFormGroup,
  },
  props: {
    ids: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      isProcessing: false,
      reprocessType: 'all',
    }
  },
  methods: {
    confirmHandler(event) {
      event.preventDefault()

      this.isProcessing = true

      axios.post('/pipeline/re_process_email_batches/', {
        ids: this.ids,
        reprocess_type: this.reprocessType,
      })
        .then(() => {
          this.$emit('completed')

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Email Batches Queued for re-processing',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.showModal = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.detail || 'Somthing went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          this.isProcessing = false
        })
    },
  },
}

</script>
